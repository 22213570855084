import React, { useRef } from "react";
import Layout from "../../components/Layout";
import { Col, Row } from "reactstrap";
import wallsImg from "../../assets/images/walls.png";
// import RecentNews from "../../components/RecentNews/RecentNews";
// import Card from "../../components/Card/Card";
// import BuildingGuideModal from "../../components/Modals/BuildingGuideModal/BuildingGuideModal";
// import houseImg from "../../assets/images/walls-house.png";
// import wallsYtong from "../../assets/images/walls-ytong.jpg";
// import wallsDescription from "../../assets/images/walls-description2.jpg";
// import wallsDescription2 from "../../assets/images/walls-description-image.svg";
// import buildingGuideImg from "../../assets/images/building-guide.png";
// import ecoDeclaration from '../../assets/images/eco-declaration.png';
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const WallsSilka = () => {
  // const [isModalOpen, setModalOpen] = useState(false);

  const silkaThermo = useRef(null);
  const silkaKomfort = useRef(null);
  // const silkaAkustik = useRef(null);

  const scrollToElement = (ref) => {
    ref.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };
  
  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }
  
  return (
    <>
      <Layout description={urlDescription[url.pathname]}>
        <div className="walls-decision-wrapper">
          <div className="walls-decision-container">
            <Row className="walls-decision-top-row">
              <Col className="walls-menu-col" lg={4}>
                <h1 className="h2 font-weight-bold">Решения за стени SILKA</h1>
                <div className="walls-menu">
                  <div className="walls-menu-list">
                    <hr />
                    <div
                      role="button"
                      tabIndex={0}
                      className="clickable my-2"
                      onKeyDown={() => {
                        scrollToElement(silkaThermo);
                      }}
                      onClick={() => {
                        scrollToElement(silkaThermo);
                      }}
                    >
                      SILKA 1
                    </div>
                    <hr />
                    <div
                      role="button"
                      tabIndex={0}
                      className="clickable my-2"
                      onKeyDown={() => {
                        scrollToElement(silkaKomfort);
                      }}
                      onClick={() => {
                        scrollToElement(silkaKomfort);
                      }}
                    >
                      SILKA 2
                    </div>
                    <hr />
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <img alt="wall" className="w-100" src={wallsImg} />
              </Col>
            </Row>
          </div>
        </div>
        {/* <RecentNews /> */}
      </Layout>
    </>
  );
};

export default WallsSilka;
